export { default as Card } from './Card';
export { default as Table } from './Table';

export const formatPrice = (number: number, sperator?: string) => {
  return number !== null && typeof number === 'number'
    ? number?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${sperator ?? ','}`)
    : 0;
};

export const combineUrlParams = (url = '', params = {}) => {
  const keys = Object.keys(params);
  const paramUrl = keys
    .filter((key) => ![undefined, null, ''].includes(params[key]))
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return `${url}?${paramUrl}`;
};

export function forceDownload(blob, filename) {
  const a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export const EXCEL_FORMAT_FILE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export enum accountType {
  SUPER_ADMIN = 1,
  ADMIN,
  CONSUMER, //Tiêu thụ'
  ACCOUNTANT, //Kế toán
  AGENCY, //Đại lý
  MARKET,
}

export const positionType = {
  TRUONG_PHONG: '001',
  PHO_PHONG: '002',
  NHAN_VIEN: '003',
};

export enum accountNameType {
  SUPER_ADMIN = 'Super admin',
  ADMIN = 'Admin',
  CONSUMER = 'Tiêu thụ', //Tiêu thụ'
  ACCOUNTANT = 'Kế toán', //Kế toán
  AGENCY = 'Đại lý', //Đại lý
  MARKET = 'Thị trường',
}

export const accountInternalTypeArr = [
  {
    id: accountType.ADMIN,
    name: 'Admin',
  },
  {
    id: accountType.CONSUMER,
    name: 'Tiêu thụ',
  },
  {
    id: accountType.ACCOUNTANT,
    name: 'Kế toán',
  },
  {
    id: accountType.MARKET,
    name: 'Thị trường',
  },
];

export const unitArr = [
  {
    id: 1,
    name: 'Bao',
  },
];

export enum OrderStatusNumberEnum {
  DA_HUY = 1,
  DA_TU_CHOI = 2,
  CHO_DUYET = 3,
  DA_DUYET_SL_VA_LUU_NB = 4,
  DA_DUYET_SL_VA_THONG_BAO_DL = 5,
  DA_DUYET_XUAT_HANG = 6,
  DANG_GIAO_HANG = 7,
  HOAN_THANH_GIAO_HANG = 8,
  DA_DUYET = 9,
}
export const OrderStatusTypeArray = [
  {
    id: OrderStatusNumberEnum.DA_HUY,
    name: 'Đã hủy',
  },
  {
    id: OrderStatusNumberEnum.DA_TU_CHOI,
    name: 'Đã từ chối',
  },
  {
    id: OrderStatusNumberEnum.CHO_DUYET,
    name: 'Chờ duyệt',
  },
  {
    id: OrderStatusNumberEnum.DA_DUYET_SL_VA_LUU_NB,
    name: 'Đã duyệt SL và lưu NB',
  },
  {
    id: OrderStatusNumberEnum.DA_DUYET_SL_VA_THONG_BAO_DL,
    name: 'Đã duyệt SL và thông báo DL',
  },
  {
    id: OrderStatusNumberEnum.DA_DUYET_XUAT_HANG,
    name: 'Đã duyệt xuất hàng',
  },
  {
    id: OrderStatusNumberEnum.DANG_GIAO_HANG,
    name: 'Đang giao hàng',
  },
  {
    id: OrderStatusNumberEnum.HOAN_THANH_GIAO_HANG,
    name: 'Hoàn thành giao hàng',
  },
];

export enum OrderCashStatusNumberEnum {
  DA_THANH_TOAN = 1,
  CHUA_THANH_TOAN,
  CHO_XAC_NHAN_THANH_TOAN,
  TRA_SAU,
}

export const OrderCashStatusNumberArray = [
  {
    id: OrderCashStatusNumberEnum.DA_THANH_TOAN,
    name: 'Đã thanh toán',
  },
  {
    id: OrderCashStatusNumberEnum.CHUA_THANH_TOAN,
    name: 'Chưa thanh toán',
  },
  {
    id: OrderCashStatusNumberEnum.CHO_XAC_NHAN_THANH_TOAN,
    name: 'Chờ xác nhận thanh toán',
  },
  {
    id: OrderCashStatusNumberEnum.TRA_SAU,
    name: 'Trả sau',
  },
];
// trạng thái đơn trả hàng
export enum ReturnOrderStatusTypeNumber {
  DRAFT = 0,
  ORDER_PENDING = 1,
  APPROVED_QUANTITY = 2,
  DONE = 3,
  REFUSED = 4,
  CANCELLED = 5,
}

export const ReturnOrderStatusTypeArray = [
  // {
  //   id: 1,
  //   name: 'Chờ duyệt trả',
  // },
  {
    id: 1,
    name: 'Chờ duyệt',
  },
  {
    id: 2,
    name: 'Đã duyệt số lượng trả',
  },
  {
    id: 3,
    name: 'Hoàn thành',
  },
  {
    id: 4,
    name: 'Đã từ chối',
  },
  // {
  //   id: 5,
  //   name: 'Đã hủy trả',
  // },
];

// Loại tiền:
export enum TransactionCashEnum {
  TIEN_VAO = 'Tiền vào',
  TIEN_RA = 'Tiền ra',
}

export enum TransactionCashNumberEnum {
  TIEN_VAO = 1,
  TIEN_RA = 2,
}

// Hình thức giao dịch:
export enum TransactionFormEnum {
  CHUYEN_KHOAN = 'Chuyển khoản',
  TIEN_MAT = 'Tiền mặt',
  CONG_CONG_NO = 'Cộng công nợ',
  TRU_CONG_NO = 'Trừ công nợ',
}

export enum TransactionFormNumberEnum {
  CHUYEN_KHOAN = 1,
  TIEN_MAT = 2,
  CONG_CONG_NO = 3,
  TRU_CONG_NO = 4,
}

// Loại giao dịch:
export enum TransactionTypeEnum {
  NAP_TIEN_VAO_TAI_KHOAN = 'Nạp tiền vào tài khoản',
  HOAN_TIEN_DON_HANG_TRA = 'Hoàn tiền đơn hàng',
  THANH_TOAN_DON_HANG = 'Thanh toán đơn hàng',
  RUT_TIEN_TU_TAI_KHOAN = 'Rút tiền từ tài khoản',
  HOAN_TIEN = 'Hoàn tiền',
}

export enum TransactionTypeNumberEnum {
  NAP_TIEN_VAO_TAI_KHOAN = 1,
  HOAN_TIEN_DON_HANG_TRA = 2,
  THANH_TOAN_DON_HANG = 3,
  RUT_TIEN_TU_TAI_KHOAN = 4,
  HOAN_TIEN = 5,
}

// trạng thái đơn hàng đặt
export const orderingProductionReportTypeArray = [
  {
    id: 0,
    name: 'Tất cả trạng thái',
  },
  {
    id: 3,
    name: 'Chờ duyệt',
  },
  {
    id: 4,
    name: 'Đã duyệt SL và lưu NB',
  },
  {
    id: 5,
    name: 'Đã duyệt SL và thông báo DL',
  },
  {
    id: 6,
    name: 'Đã duyệt xuất hàng',
  },
];
