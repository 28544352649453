import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// type cartInfo = {
//   id: number;
//   name: string;
//   amount: number;
//   price: number;
// };

type initType = {
  area: Array<any>;
};

const initialState:initType = {
  area: [],
};

const AreaSlice = createSlice({
  name: 'area',
  initialState: initialState,
  reducers: {
    searchListArea: (state: any, action: PayloadAction<any>) => {
 state.area = action.payload
    }
  }
   
});

export const { searchListArea } = AreaSlice.actions;

export default AreaSlice.reducer;
