import { combineReducers } from 'redux'
import areaReducer from 'src/feature/area'
import transactionMethodReducer from 'src/feature/transactionMethod'
import transactionTypeReducer from 'src/feature/transactionType'
import notificationReducer from 'src/feature/notification'
export default combineReducers({
  // app: appReducer,
  // authentication: authenticationReducer,
  area: areaReducer,
  transactionMethod: transactionMethodReducer,
  transactionType:transactionTypeReducer,
  notification:notificationReducer
})

