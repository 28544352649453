import { message } from 'antd';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AUTH } from './api';
import { AppRoutes } from './app.routes';

// import dotenv from 'dotenv'
// dotenv.config()

let configNetwork: any = {
  _retry: false,
};

export const HOST = 'https://appdms.thanglongtabac.vn/';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (config.url !== AUTH.REFRESH_TOKEN) {
    const accessToken = `Bearer ${localStorage.getItem('accessToken')}`;
    config.headers = {
      Authorization: accessToken,
    };
  }
  return config;
};

const onRequestError = async (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return await Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: any) => {
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  if (
    error.response?.status === 401 &&
    error.config.url !== AUTH.LOGOUT &&
    error.config.url !== AUTH.REFRESH_TOKEN &&
    !configNetwork._retry
  ) {
    if (error.response?.data?.statusCode === 401) {
      message.error(error.response?.data?.message);
    }
    const res = await fetchRefreshToken(error);
    return res;
  } else if (error.response?.status === 403 && error.config.url !== AUTH.LOGIN) {
    window.location.href = AppRoutes.page403;
    return await Promise.reject(error);
  } else {
    return await Promise.reject(error);
  }
};

const headers = {};

const axiosIns = axios.create({
  baseURL: HOST, // process.env.REACT_APP_BACKEND_DEV_URL,
  headers,
  timeout: 30 * 60 * 1000,
  withCredentials: true,
});

export const syncAuthToDevice = (data: any) => {
  if (data) {
    // await asyncStorageService.syncToDevice(AsyncStorageKey.USER, data?.user);
    localStorage.setItem('accessToken', data?.data?.data?.tokens?.accessToken?.value);
    localStorage.setItem('refreshToken', data?.data?.data?.tokens?.refreshToken?.value);
    // console.log(AsyncStorageKey.ACCESS_TOKEN, `Bearer ${data?.accessToken}`);
  }
};

const actionLogout = () => {
  window.location.href = '/logout';
};

axiosIns.interceptors.response.use((response) => {
  if (response.data.code === 401) {
    message.error('Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập để tiếp tục sử dụng!');
    actionLogout();
  }
  return response;
});

const fetchRefreshToken = async (error: any) => {
  const refreshToken = localStorage.getItem('refreshToken');
  try {
    const response = await axios.get(`${AUTH.GET_TOKEN}`, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
      baseURL: HOST,
    });

    if (response !== undefined) {
      syncAuthToDevice(response);
      configNetwork = error.response.config;
      configNetwork.Authorization = `Bearer ${response?.data?.data?.tokens?.accessToken?.value}`;
      const res = await axiosIns(configNetwork);
      // console.log(res);
      return res;
    } else {
      localStorage.clear();
      message.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại ');
      return setTimeout((window.location.href = AppRoutes.login), 1000);
    }
  } catch (err) {
    localStorage.clear();
    message.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại ');
    setTimeout((window.location.href = AppRoutes.login), 1000);
  }
};

axiosIns.interceptors.request.use(onRequest, onRequestError);
axiosIns.interceptors.response.use(onResponse, onResponseError);

export default axiosIns;
