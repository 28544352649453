import { Card } from 'antd';
import React from 'react';
import { formatPrice } from '..';
import styles from './styles.module.scss';

export default function index({ name, number, icon, link }) {
  return (
    <Card
      style={{ borderRadius: 16, paddingTop: 15 }}
      onClick={() => (window.location.href = link)}
      // extra={<a href={link}>{'>'}</a>}
      // title={
      //   <div className="title-head">
      //     <div className="title-icon">{icon}</div>
      //     <p className="title-name">{name}</p>
      //   </div>
      // }
      bordered={false}
      className={styles.container}
    >
      <div>
        <div className="title-head">
          <div className="title-icon">{icon}</div>
          <p className="title-name">{name}</p>
          <p className="title-arrow">
            <strong>{'>'}</strong>
          </p>
        </div>
        <p className="title-number">{formatPrice(number, ',')}</p>
      </div>
    </Card>
  );
}
