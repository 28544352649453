/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppRoutes } from '../helpers/app.routes';
import MainLayout from '../layouts/Main';

import { Page403 } from '../pages/ErrorPage/403';
import { Page404 } from '../pages/ErrorPage/404';
import { routers } from './constants';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { useNotificationState } from 'src/hook/useNotificationState';
import { fetchNotification } from 'src/api/notification';
import logo from 'src/components/background-img/logo.png';
// import { AppRoutes } from 'src/helpers/app.routes';
// import MainLayout from 'src/layouts/Main';
// import { routes } from './constants';
// import { localStore } from 'src/common/localStorage';
// import { useCurrentUser } from 'src/hooks/useCurrentUser';
// import axiosIns from 'src/helpers/request';
// import { AUTH } from 'src/helpers/api';
// import CustomYoutubePlayer from 'src/pages/video/index';
// import PublicTermsAndPolicies from 'src/pages/public-dieu-khoan-va-chinh-sach';
// import { initSocket, socketClient } from 'src/components/Socket';
// import { useMessageState } from 'src/hooks/useMessageState';

const HomePage = React.lazy(async () => await import('src/pages/home'));
const LoginPage = React.lazy(async () => await import('src/pages/login'));
// quên mật khẩu
const ForgetPasswordPage = React.lazy(async () => await import('src/pages/quen-mat-khau'));
const GetOTPPage = React.lazy(async () => await import('src/pages/lay-ma-otp'));
const ResetPasswordPage = React.lazy(async () => await import('src/pages/lay-mat-khau-moi'));

export const RootRouter = React.memo(() => {
  const [isNotificaiton, setIsNotification] = useState<any>({});
  const { onAddNotification, dataNotificationList } = useNotificationState();
  // const { onAddArea, dataAreaList } = useAreaState();
  const allowModules: any = useMemo(() => {
    // let results: any = {}
    const results = localStorage.getItem('role');
    if (results === localStorage.getItem('role') && results !== 'undefined' && results !== undefined) {
      return results;
    }
    // ae tự xử lý logout
    return {};
  }, [localStorage.getItem('role')]);

  const firebaseConfig = {
    apiKey: 'AIzaSyBM5Fj1thEzYcVTm-e92VYyMpVIRdDWTFQ',
    authDomain: 'tltl-api-fd9f2.firebaseapp.com',
    // databaseURL: "https://tltl-api-fd9f2-default-rtdb.firebaseio.com",
    projectId: 'tltl-api-fd9f2',
    storageBucket: 'tltl-api-fd9f2.appspot.com',
    messagingSenderId: '52253525207',
    appId: '1:52253525207:web:f8fb132068f0fc9bfc9c92',
    measurementId: 'G-RBKD6G3H0G',
  };

  function requestPermission() {
    // console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      // console.log('permission', permission);

      if (permission === 'granted') {
        // console.log('Notification permission granted.');
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        onMessage(messaging, (payload) => {
          // console.log('Message received. ', payload);
          let clickAction = payload?.data?.webUrl;
          const notificationTitle = payload?.data?.title || '';
          const notificationOptions = {
            body: payload?.data?.body,
            icon: logo,
            data: {
              click_action: clickAction,
            },
          };

          var notification = new Notification(notificationTitle, notificationOptions);
          notification.onclick = function (event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(clickAction, '_blank');
            notification.close();
          };
          setIsNotification(payload);
        });
        navigator.serviceWorker.addEventListener('message', function handler(event) {
          // console.log('event', event?.data?.notification);
          setIsNotification(event?.data);
        });

        getToken(messaging, {
          vapidKey: 'BGhCSN6xwKzhBMm5s4o9mPfxyX2zK05kP5g87RxhgQ3m4Cx8CCNPeZB7VwBNNsmcYKuuCH_TznZ2DprW0ZKhq7Y',
        }).then((currentToken) => {
          if (currentToken) {
            localStorage.setItem('tokenFirebase', currentToken);
          } else {
            // console.log('Can not get token');
          }
        });
      } else {
        console.log('Do not have permission!');
      }
    });
  }

  requestPermission();

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      const fetchDataNotification = async () => {
        const data = await fetchNotification();
        onAddNotification(data);
      };
      fetchDataNotification();
    }
  }, [isNotificaiton]);

  return (
    <Routes>
      <Route path={AppRoutes.login} element={<LoginPage isLogOut={false} />} />
      <Route path={AppRoutes.forgot_password} element={<ForgetPasswordPage />} />
      <Route path={AppRoutes.get_otp} element={<GetOTPPage />} />
      <Route path={AppRoutes.get_new_password} element={<ResetPasswordPage />} />
      <Route path={AppRoutes.home} element={<MainLayout currentUser={localStorage.getItem('role')} />}>
        <Route index element={<HomePage />} />
        {routers.map((route: any, index: any) => {
          const isExistFromRole = route.permission?.includes(allowModules);

          return (
            <Route
              key={`route${index}`}
              path={route.path}
              element={isExistFromRole ? route.element : <Page403 />}
              // element={route.element}
            />
          );
        })}
      </Route>
      <Route path={AppRoutes.page404} element={<Page404 />} />
      <Route path={AppRoutes.page403} element={<Page403 />} />
      <Route path={AppRoutes.logout} element={<LoginPage isLogOut={true} />} />
    </Routes>
  );
});
