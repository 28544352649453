import React from 'react'
import { ProfileUser } from '../../components/ProfileUser'
import styles from './styles.module.scss'

export const Header: React.FC = () => {
  return (
    <div className={styles.wrapHeader}>
      <ProfileUser />
    </div>
  )
}
