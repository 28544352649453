import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// type cartInfo = {
//   id: number;
//   name: string;
//   amount: number;
//   price: number;
// };

type initType = {
  transactionType: Array<any>;
};

const initialState:initType = {
  transactionType: [],
};

const TransactionTypeSlice = createSlice({
  name: 'transactionType',
  initialState: initialState,
  reducers: {
    searchListTransactionType: (state: any, action: PayloadAction<any>) => {
 state.transactionType = action.payload

    }
  }
   
});

export const { searchListTransactionType } = TransactionTypeSlice.actions;

export default TransactionTypeSlice.reducer;
