import { Badge, Button, Dropdown, Form, Input, Modal, Popover, Space, Spin, Tooltip, Typography, message } from 'antd';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import axiosIns from '../../helpers/request';
import { AUTH, NOTIFICATION_API } from '../../helpers/api';
import { AppRoutes, OrderRoutes, TransactionRoutes, OrderReturnRoutes } from '../../helpers/app.routes';
import { ArrowDownIcon, AvatarIcon, BellIcon, LogOutIcon, MSBSmallLogoIcon } from '../Icon';
import { ExclamationCircleFilled, InfoCircleOutlined, LockOutlined } from '@ant-design/icons';
import { formValidateMessages } from 'src/pages/constants';
import { useNotificationState } from 'src/hook/useNotificationState';
import { combineUrlParams } from 'src/common';
import moment from 'moment';
// import '../../firebase';

export const ProfileUser: React.FC = () => {
  const [hovered, setHovered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNoti, setIsLoadingNoti] = useState(false);
  const [page, setPage] = useState<any>(1);
  const { loadMoreNoti, dataNotificationList, dataNotificationTotalItems, dataNotificationUnSeenCount } =
    useNotificationState();
  const userDataString: any = localStorage.getItem('userInfor');
  const userData: any = JSON.parse(userDataString);
  const navigate = useNavigate();
  const handleCancel = () => {
    form.resetFields();
    setModalOpen(false);
  };
  const [form] = Form.useForm();

  //gọi API lấy thông báo
  function fetchNotificaiton(value: any) {
    setIsLoadingNoti(true);
    return new Promise((resolve, reject) => {
      axiosIns
        .get(
          combineUrlParams(`${NOTIFICATION_API.SEARCH}`, {
            page: value,
            limit: 10,
          })
        )
        .then((s) => {
          let data = dataNotificationList?.concat(s?.data?.data?.notificationList);
          loadMoreNoti(data);
          resolve(s?.data?.data?.notificationList);
          setIsLoadingNoti(false);
        })
        .catch((e) => {
          reject(e);
          setIsLoadingNoti(false);
        });
    });
    // .then
  }

  async function handleLogout() {
    return await new Promise((resolve, reject) => {
      axiosIns
        .get(AUTH.LOGOUT)
        .then((s) => {
          resolve(s);
          localStorage.clear();
        })
        .catch((e) => {
          localStorage.clear();
        });
      navigate(AppRoutes.login);
    });
  }

  const handleChangePass = () => {
    setModalOpen(true);
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      axiosIns
        .put(`${AUTH.CHANGE_PASSWORD}/${userData.id}`, { ...values, phoneNumber: userData?.phoneNumber })
        .then((s) => {
          setIsLoading(false);
          if (s?.data?.statusCode === 200) {
            message.success(s?.data?.message);
            handleCancel();
          } else if (s?.data?.statusCode >= 202) {
            message.error(s?.data?.message);
          }
          resolve(s);
        })
        .catch((e) => {
          setIsLoading(false);
          message.error(e?.response?.data?.message);
        });
    });
  };

  const handleRead = (itemId: any, action: string, dataId: any) => () => {
    // debugger;
    return new Promise((resolve, reject) => {
      axiosIns
        .put(`${NOTIFICATION_API.SEEN}/${itemId}`)
        .then(() => {
          if (action === 'ORDER_DETAIL') {
            window.location.href = OrderRoutes.details(dataId?.id);
          } else if (action === 'TRANSACTION_DETAIL') {
            window.location.href = TransactionRoutes.details(dataId?.id);
          } else {
            window.location.href = OrderReturnRoutes.details(dataId?.id);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (dataNotificationList?.length < dataNotificationTotalItems) {
        setPage(page + 1);
        fetchNotificaiton(page + 1);
      }
    }
  };

  const content = (
    <div className={styles.scrollBarWrap}>
      <div className={styles.notification} onScroll={handleScroll}>
        {dataNotificationList?.map((item: any, index: number) => {
          if (item?.isSeen) {
            return (
              <div className={styles.info} key={index}>
                <MSBSmallLogoIcon className={styles.logoNoti} />
                <div
                  title={item?.content}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                  }}
                  key={item?.id}
                  onClick={handleRead(item?.id, item?.action, item?.data)}
                >
                  {item?.content}
                  <br />
                  <p>{moment(item?.createdAt)?.format('DD/MM/YYYY HH:mm:ss')}</p>
                </div>
              </div>
            );
          } else {
            return (
              <div className={styles.notRead} key={item?.id}>
                <MSBSmallLogoIcon className={styles.logoNoti} />
                <div
                  title={item?.content}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    WebkitLineClamp: '2 !impotant',
                    width: '100%',
                  }}
                  key={item?.id}
                  onClick={handleRead(item?.id, item?.action, item?.data)}
                >
                  {item?.content}
                  <br />
                  <p>{moment(item?.createdAt)?.format('DD/MM/YYYY HH:mm:ss')}</p>
                </div>
                <div>
                  <ExclamationCircleFilled className={styles.iconWarning} />
                </div>
              </div>
            );
          }
        })}
        {isLoadingNoti ? <Spin style={{ display: 'flex', justifyContent: 'center' }} /> : <></>}
      </div>
      <div className={styles.coverBar}></div>
    </div>
  );

  return (
    <>
      <Popover
        showArrow={false}
        placement="bottom"
        title={<Typography.Title level={3}>Thông báo</Typography.Title>}
        content={content}
        trigger="click"
        style={{ borderRadius: '10px', overflow: 'auto' }}
      >
        <Badge count={dataNotificationUnSeenCount} offset={[-14, 6]}>
          <BellIcon className={styles.bell} />
        </Badge>
      </Popover>
      <Dropdown
        visible={hovered}
        onVisibleChange={setHovered}
        placement="bottomRight"
        trigger={['click']}
        className={styles.wrapInfo}
        overlay={
          <div className={styles.dropDownContainer}>
            <div className={styles.dropDown}>
              <LockOutlined style={{ fontSize: '20px', color: '#1F81BB' }} />
              <span className={styles.textModal}>
                <Button className={styles.dropDownBtn} onClick={() => handleChangePass()}>
                  Đổi mật khẩu
                </Button>
              </span>
            </div>
            <div className={styles.dropDown}>
              <LogOutIcon />
              <span className={styles.textModal}>
                <Button className={styles.dropDownBtn} onClick={() => handleLogout()}>
                  Đăng xuất
                </Button>
              </span>
            </div>
          </div>
        }
      >
        <div>
          <AvatarIcon />
          <div className={styles.content}>
            <span className={styles.textGrey}>Xin chào</span>
            <p className={styles.textProfileName}>{userData?.fullName}</p>
          </div>
          <div
            className={classNames(styles.arrowIcon, {
              [styles.active]: hovered,
            })}
          >
            <ArrowDownIcon />
          </div>
        </div>
      </Dropdown>
      <Modal
        width={600}
        visible={modalOpen}
        title={<strong>Đổi mật khẩu</strong>}
        footer={null}
        onCancel={handleCancel}
      >
        <Spin spinning={isLoading}>
          <Form
            labelAlign="left"
            validateMessages={formValidateMessages}
            form={form}
            autoComplete="off"
            onFinish={onFinish}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item label="Mật khẩu cũ" name="currentPassword" rules={[{ required: true }]}>
              <Input.Password placeholder="Nhập mật khẩu" />
            </Form.Item>
            <Form.Item label="Mật khẩu mới" name="newPassword" rules={[{ required: true }]}>
              <Input.Password
                placeholder="Nhập mật khẩu"
                prefix={
                  <Tooltip
                    title={
                      'Mật khẩu chứa tối thiểu 8 ký tự, tối đa 255 ký tự và có ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt.'
                    }
                  >
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu mới"
              name="reNewPassword"
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Mật khẩu không trùng khớp'));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Nhập mật khẩu"
                prefix={
                  <Tooltip
                    title={
                      'Mật khẩu chứa tối thiểu 8 ký tự, tối đa 255 ký tự và có ít nhất một chữ hoa, một chữ thường, một số và một ký tự đặc biệt.'
                    }
                  >
                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                  </Tooltip>
                }
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Xác nhận
                </Button>
                <Button type="primary" danger onClick={() => handleCancel()}>
                  Hủy
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
