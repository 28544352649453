import { Table } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';

export default function Index(props) {
  const dataWidth = useRef<any>({});

  const [state, _setState] = useState({
    columns: [],
  });

  const setState = (_state) => {
    _setState((state) => ({
      ...state,
      ...(_state || {}),
    }));
  };

  // let className = 'custom-table '
  // if (props.className) className += props.className

  useEffect(() => {
    const columns = (props.columns || []).filter((item) => !item.hidden);
    setState({
      columns,
    });
  }, [props.columns]);

  const columns = useMemo(() => {
    const columns = (state.columns || []).map((col: any, index) => ({
      ...col,
      key: 'col' + index,
      width: dataWidth.current['col' + index] || col.width,
      onHeaderCell: (column: any) => ({
        width: column.width,
        // onResize: handleResize(index),
      }),
    }));

    return columns;
  }, [state.columns]);

  return (
    // <ReactDragListView.DragColumn {...dragProps}>
    <Table
      locale={{ emptyText: 'Danh sách trống' }}
      components={
        {
          // header: {
          //   cell: ResizableTitle,
          // },
        }
      }
      // bordered
      {...props}
      id="components-table-demo-resizable-column"
      columns={columns}
      className={styles.tableWrapper}
    />
  );
}
