import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// type cartInfo = {
//   id: number;
//   name: string;
//   amount: number;
//   price: number;
// };

type initType = {
  notification: Array<any>;
  currentItems: any;
  totalItems: any;
  unSeenCount: any;
};

const initialState: initType = {
  notification: [],
  currentItems: null,
  totalItems: null,
  unSeenCount: null,
};

const NotificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    searchListNotification: (state: any, action: PayloadAction<any>) => {
      const { currentItems, notificationList, totalItems, unSeenCount } = action.payload;
      let _data = state.notification;
      
      _data.push(notificationList);
      state.notification = notificationList;
      state.totalItems = totalItems;
      state.unSeenCount = unSeenCount;
    },
       loadMoreNotification(state: any, action: PayloadAction<any>) {
     state.notification = action.payload;

    },
  },
});

export const { searchListNotification,loadMoreNotification } = NotificationSlice.actions;

export default NotificationSlice.reducer;
