import { Badge, Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MSBLogoIcon, MSBSmallLogoIcon } from '../../components/Icon';
import { AppRoutes } from '../../helpers/app.routes';
import type { IMenuItem, MenuItem } from '../types';
import { menus } from './constants';
import styles from './styles.module.scss';

interface Props {
  allowModules?: any;
  currentUser?: any;
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const { Sider } = Layout;

export const SideBar: React.FC<Props> = ({ collapsed, setCollapsed, currentUser }) => {
  function getMenuItems(menus: IMenuItem[]) {
    return menus
      .filter((item) => item.key?.includes(currentUser))
      .map((menu: IMenuItem) => {
        if (menu.submenu && menu.submenuItems != null) {
          return getItem(
            menu.title,
            menu.key,
            menu.icon,
            menu.submenuItems
              .filter((subItem) => subItem.key?.includes(currentUser))
              .map((subMenuItem) =>
                getItem(
                  <NavLink className={styles.bold} to={subMenuItem.to} key={subMenuItem.key}>
                    {subMenuItem.title}
                  </NavLink>,
                  subMenuItem.key,
                  subMenuItem.icon
                )
              )
          );
        }

        return getItem(
          <NavLink className={styles.bold} to={menu.to} key={menu.key}>
            {menu.title}
          </NavLink>,
          menu.key,
          menu.icon
        );
      });
  }

  const items: MenuItem[] = getMenuItems(menus);

  return (
    <Sider
      collapsible
      onCollapse={(value) => {
        setCollapsed(value);
      }}
      collapsed={collapsed}
      width={255}
      className={styles.slider}
    >
      <NavLink to={AppRoutes.home} className={styles.logo}>
        <div className={styles.logoWrapper}>{collapsed ? <MSBSmallLogoIcon /> : <MSBLogoIcon />}</div>
      </NavLink>
      <div className={styles.sidebar}>
        <Menu mode="inline" style={{ width: collapsed ? 80 : 255 }} items={items} />
      </div>
    </Sider>
  );
};
