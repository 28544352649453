export const AppRoutes = {
  home: '/',
  login: '/login',
  page404: '*',
  page403: '/403',
  forgot_password: '/quen-mat-khau',
  get_otp: '/lay-ma-otp',
  get_new_password: '/lay-mat-khau-moi',
  logout: '/logout',
};

// danh mục tài khoản đại lý
export const AccountRoutes = {
  list: '/danh-muc-tai-khoan-dai-ly/danh-sach',
  create: '/danh-muc-tai-khoan-dai-ly/tao-moi',
  detail: '/danh-muc-tai-khoan-dai-ly/chi-tiet/:id',
  details: (id: string | number) => `/danh-muc-tai-khoan-dai-ly/chi-tiet/${id}`,
};

// danh mục tài khoản nội bộ
export const AccountInternalRoutes = {
  list: '/danh-muc-tai-khoan-noi-bo/danh-sach',
  create: '/danh-muc-tai-khoan-noi-bo/tao-moi',
  detail: '/danh-muc-tai-khoan-noi-bo/chi-tiet/:id',
  details: (id: string | number) => `/danh-muc-tai-khoan-noi-bo/chi-tiet/${id}`,
};

// quản lý sản phẩm
export const ProductRoutes = {
  list: '/quan-ly-san-pham/danh-sach',
  create: '/quan-ly-san-pham/tao-moi',
  detail: '/quan-ly-san-pham/chi-tiet/:id',
  details: (id: string | number) => `/quan-ly-san-pham/chi-tiet/${id}`,
};

// quản lý dòng sản phẩm
export const ProductCategoryRoutes = {
  list: '/danh-muc-dong-san-pham/danh-sach',
  create: '/danh-muc-dong-san-pham/tao-moi',
  detail: '/danh-muc-dong-san-pham/chi-tiet/:id',
  details: (id: string | number) => `/danh-muc-dong-san-pham/chi-tiet/${id}`,
};

// quản lý giao dich
export const TransactionRoutes = {
  lists: (id: string | number | undefined) => `/quan-ly-giao-dich/danh-sach/${id}`,
  list: `/quan-ly-giao-dich/danh-sach/:id`,
  creates: (id: string | number) => `/quan-ly-giao-dich/tao-moi/${id}`,
  create: `/quan-ly-giao-dich/tao-moi/:id`,
  detail: '/quan-ly-giao-dich/chi-tiet/:id',
  details: (id: string | number) => `/quan-ly-giao-dich/chi-tiet/${id}`,
};

// danh sách công nợ
export const DebtRoutes = {
  list: '/danh-sach-cong-no/danh-sach',
  create: '/danh-sach-cong-no/tao-moi',
  detail: '/danh-sach-cong-no/chi-tiet/:id',
  details: (id: string | number) => `/danh-sach-cong-no/chi-tiet/${id}`,
};

// danh sách đơn hàng
export const OrderRoutes = {
  list: '/danh-sach-don-hang/danh-sach',
  detail: '/danh-sach-don-hang/chi-tiet-don-hang/:id',
  details: (id: string | number) => `/danh-sach-don-hang/chi-tiet-don-hang/${id}`,
};

// danh sách đơn hàng trả
export const OrderReturnRoutes = {
  list: '/danh-sach-don-hang-tra/danh-sach',
  detail: '/danh-sach-don-hang-tra/chi-tiet-don-hang-tra/:id',
  details: (id: string | number) => `/danh-sach-don-hang-tra/chi-tiet-don-hang-tra/${id}`,
};

// danh sách đơn hàng đặt
export const OrderPlaceRoutes = {
  list: '/danh-sach-don-hang-dat/danh-sach',
  detail: '/danh-sach-don-hang-dat/chi-tiet-don-hang-dat/:id',
  details: (id: string | number, name: string, total: number, status: number) =>
    `/danh-sach-don-hang-dat/chi-tiet-don-hang-dat/${id}?name=${name}&total=${total}&status=${status}`,
};

// phản hồi khách hàng
export const FeedbackRoutes = {
  list: '/phan-hoi-khach-hang/danh-sach',
};

// điều hướng thanh toán
export const PaymentNagvigationRoutes = {
  list: '/dieu-huong-thanh-toan/danh-sach',
};

// danh sách khu vực
export const AreaRoutes = {
  list: '/danh-muc-khu-vuc/danh-sach',
  create: '/danh-muc-khu-vuc/tao-moi',
  detail: '/danh-muc-khu-vuc/chi-tiet/:id',
  details: (id: string | number) => `/danh-muc-khu-vuc/chi-tiet/${id}`,
};

//danh sách tài khoản ngân hàng
export const BankRoutes = {
  list: '/danh-muc-tai-khoan-ngan-hang/danh-sach',
};

//báo cáo
export const ReportRoutes = {
  list: '/bao-cao/danh-sach',
  listV2: '/bao-cao-V2/danh-sach',
  detailV2: '/bao-cao-V2/chi-tiet/:id',
  detailV2s: (id: string | number) => `/bao-cao-V2/chi-tiet/${id}`,
};

export const GuaranteeRoutes = {
  list: '/danh-sach-bao-lanh-the-chap/danh-sach',
};
