import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { SideBar } from '../SideBar';
import { Outlet, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Header } from '../Header';
import { AppRoutes } from '../../helpers/app.routes';
import Background from '../../components/background-img/background.jpg';

interface Props {
  allowModules?: any;
  currentUser?: any;
}

const MainLayout = ({ allowModules, currentUser }: Props) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  // useEffect(() => {
  //   if (!localStorage.getItem('accessToken')) {
  //     navigate(AppRoutes.login);
  //   }
  // }, []);

  return (
    <Layout>
      <SideBar
        // style={{ backgroundImage: `url(${Background})` }}
        // allowModules={allowModules}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
        currentUser={currentUser}
      />
      <Layout>
        <div className={classNames(styles.section, { [styles.collapsed]: collapsed, [styles.expanded]: !collapsed })}>
          <Header />
          <div className={styles.content}>
            <Outlet context={allowModules} />
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
