import { Button, Result } from 'antd'
import React from 'react'
import { AppRoutes } from '../../helpers/app.routes'

export const Page403: React.FC = () => (
  <Result
    status="403"
    title="403"
    subTitle="Xin lỗi, bạn không có đủ quyền để truy nhập trang này."
    extra={
      <Button type="primary" href={AppRoutes.home}>
        Trở về trang chủ
      </Button>
    }
  />
)
