import { Button, Result } from 'antd'
import React from 'react'
import { AppRoutes } from '../../helpers/app.routes'

export const Page404: React.FC = () => (
  <Result
    status="404"
    title="404"
    subTitle="Xin lỗi, trang của bạn đang truy nhập không tồn tại."
    extra={
      <Button type="primary" href={AppRoutes.home}>
        Trở về trang chủ
      </Button>
    }
  />
)
