import {
  AccountRoutes,
  AppRoutes,
  TransactionRoutes,
  OrderRoutes,
  ProductRoutes,
  DebtRoutes,
  OrderReturnRoutes,
  FeedbackRoutes,
  AccountInternalRoutes,
  PaymentNagvigationRoutes,
  ProductCategoryRoutes,
  AreaRoutes,
  BankRoutes,
  ReportRoutes,
  OrderPlaceRoutes,
  GuaranteeRoutes,
} from '../../helpers/app.routes';
import React from 'react';
// import {
//   AppRoutes,
//   CompanyRoutes,
//   InformationSystemRoutes,
//   QuizzRoutes,
//   SystemRoutes,
//   UserRoutes,
//   GuideRoutes,
//   AccumulatePointRoutes,
//   RoleRoutes,
//   NotificationRoutes,
//   QuesListSKNNRoutes,
//   HealthQuizRoutes,
//   ConsultationsRoutes,
//   WorkConditionRoutes,
//   HeadingListSKNNRoutes,
//   SKNNListSKNNRoutes,
//   ConclusionListSKNNRoutes,
//   ClassifyListSKNNRoutes,
//   HistoryRoutes,
// } from 'src/helpers/app.routes';

import {
  AccountIcon,
  HomeIcon,
  ListIcon,
  LogOutIcon,
  MoneyIcon,
  OrderIcon,
  ReportIcon,
  ResponseIcon,
  SettingIcon,
} from '../../components/Icon';

export {};

export const SideBarRoute = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
  CONSUMER: 'Tiêu thụ',
  ACCOUNTANT: 'Kế toán',
  AGENCY: 'Đại lý',
  MARKET: 'Thị trường',
};

export const menus = [
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT, SideBarRoute.MARKET, 'Trang chủ'],
    title: 'Trang chủ',
    to: AppRoutes.home,
    icon: <HomeIcon />,
  },
  // {
  //   key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ADMIN, SideBarRoute.CONSUMER, 'Tài khoản đại lý'],
  //   title: 'Tài khoản đại lý',
  //   to: AccountRoutes.list,
  //   icon: <OrderIcon />,
  // },
  // {
  //   key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ADMIN, 'Tài khoản nội bộ'],
  //   title: 'Tài khoản nội bộ',
  //   to: AccountInternalRoutes.list,
  //   icon: <OrderIcon />,
  // },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ADMIN, SideBarRoute.CONSUMER, 'Danh mục tài khoản'],
    title: 'Danh mục tài khoản',
    to: AppRoutes.home,
    icon: <AccountIcon />,
    submenu: true,
    submenuItems: [
      {
        title: 'Tài khoản nội bộ',
        to: AccountInternalRoutes.list,
        key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ADMIN, 'Tài khoản nội bộ'],
      },
      {
        key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Tài khoản đại lý'],
        title: 'Tài khoản đại lý',
        to: AccountRoutes.list,
      },
    ],
  },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Sản phẩm'],
    title: 'Danh mục sản phẩm',
    to: ProductRoutes.list,
    icon: <ListIcon />,
    submenu: true,
    // submenuItems: [
    //   {
    //     title: 'Danh mục sản phẩm',
    //     to: ProductRoutes.list,
    //     key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Danh mục sản phẩm'],
    //   },
    //   {
    //     key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Danh mục dòng sản phẩm'],
    //     title: 'Danh mục dòng sản phẩm',
    //     to: ProductCategoryRoutes.list,
    //   },
    // ],
  },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT, 'Đơn hàng'],
    title: 'Đơn hàng',
    to: OrderRoutes.list,
    icon: <OrderIcon />,
  },
  // {
  //   key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT, 'Đơn hàng trả'],
  //   title: 'Đơn hàng trả',
  //   to: OrderReturnRoutes.list,
  //   icon: <OrderIcon />,
  // },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Đơn hàng đặt'],
    title: 'Thống kê số lượng',
    to: OrderPlaceRoutes.list,
    icon: <OrderIcon />,
  },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ACCOUNTANT, 'Danh sách công nợ'],
    title: 'Danh sách công nợ',
    to: DebtRoutes.list,
    icon: <MoneyIcon />,
    submenu: true,
  },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.MARKET, 'Báo cáo'],
    title: 'Báo cáo',
    to: ReportRoutes.list,
    icon: <ReportIcon />,
  },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.MARKET, 'Báo cáo 2'],
    title: 'Báo cáo 2',
    to: ReportRoutes.listV2,
    icon: <ReportIcon />,
  },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Phản hồi khách hàng'],
    title: 'Phản hồi khách hàng',
    to: FeedbackRoutes.list,
    icon: <ResponseIcon />,
  },
  {
    key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Cài đặt'],
    title: 'Cài đặt',
    to: FeedbackRoutes.list,
    icon: <SettingIcon />,
    submenu: true,
    submenuItems: [
      {
        key: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, 'Khu vực'],
        title: 'Khu vực',
        to: AreaRoutes.list,
      },
      // {
      //   key: [SideBarRoute.SUPER_ADMIN, 'Điều hướng thanh toán'],
      //   title: 'Điều hướng thanh toán',
      //   to: PaymentNagvigationRoutes.list,
      // },
      {
        key: [SideBarRoute.SUPER_ADMIN, 'Tài khoản ngân hàng'],
        title: 'Tài khoản ngân hàng',
        to: BankRoutes.list,
      },
      {
        key: [SideBarRoute.SUPER_ADMIN, 'Bảo lãnh thế chấp'],
        title: 'Bảo lãnh thế chấp',
        to: GuaranteeRoutes.list,
      },
    ],
  },
];
