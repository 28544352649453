import { useDispatch, useSelector } from 'react-redux';
import { loadMoreNotification, searchListNotification } from 'src/feature/notification';
import { notificationList, notificationTotalItems, notificationUnSeenCount } from 'src/feature/notification/selectors';

export const useNotificationState = () => {
  const dispatch = useDispatch();

  const dataNotificationList = useSelector(notificationList);
  const dataNotificationTotalItems = useSelector(notificationTotalItems);
  const dataNotificationUnSeenCount = useSelector(notificationUnSeenCount);

  const onAddNotification = async (item: any) => {
    dispatch(searchListNotification(item));
  };

  const loadMoreNoti = async (item: any) => {
    dispatch(loadMoreNotification(item));
  };

  return {
    dataNotificationList,
    dataNotificationTotalItems,
    dataNotificationUnSeenCount,
    onAddNotification,
    loadMoreNoti,
  };
};
