export const AUTH = {
  LOGIN: '/api/auth/login',
  LOGOUT: '/api/auth/logout',
  REGISTER: '/api/auth/register',
  GET_TOKEN: '/api/auth/get-tokens',
  FORGOT_PASSWORD: '/api/auth/reset-password/send-otp',
  VALIDATE_OTP: '/api/auth/reset-password/validate-otp',
  RESET_PASSWORD: '/api/auth/reset-password',
  REFRESH_TOKEN: '/auth/refresh',
  CURRENT_USER: '/user/currentUserInfo',
  CHANGE_PASSWORD: '/api/user/change-pass',
};

export const HOME = {
  INFO: '/api/user/home',
};

export const UPLOAD = {
  SINGLE: '/api/transaction/upload-images',
};

export const USER_API = {
  GET_ALL: '/api/user/get-all',
  GET_LIST: '/api/user/get-list',
  CREATE: '/api/user/create-user',
  CREATE_ADMIN: '/api/user/create-admin',
  GET_BY_ID: '/api/user/get-list/detail',
  UPDATE: '/api/user/update-user',
  UPDATE_ADMIN: '/api/user/update-admin',
  DELETE: '/api/user/delete',
  GET_LIST_BY_AREA: '/api/user/get-list/agency-by-area',
  POSITION: '/api/user/position/search',
  EXPORT: '/api/user/agency-export-excel',
};

export const AREA_API = {
  GET_LIST: '/api/area/get-list',
  CREATE: '/api/area/create',
  GET_BY_ID: '/api/area/get-list/detail',
  UPDATE: '/api/area/update',
  DELETE: '/api/area/delete',
  GET_PROVINCE: '/api/area/province/search',
  GET_PROVINCE_REPORT: '/api/area/province/report/search',
};

export const PAYMENT_DIRECTION_API = {
  GET_LIST: '/api/payment-direction/card/get-list',
  CREATE: '/api/payment-direction/card/create',
  GET_BY_ID: '/api/payment-direction/card/get-list',
  UPDATE: '/api/payment-direction/card/update',
  DELETE: '/api/payment-direction/card/delete',
};

export const BANK_API = {
  GET_LIST: '/api/bank/get-brief-list',
  CREATE: '/api/bank/create',
  GET_BY_ID: '/api/bank/get-one',
  UPDATE: '/api/bank/update',
  DELETE: '/api/bank/delete',
};

export const PRODUCT_API = {
  SEARCH: '/api/production/get-list',
  CREATE: '/api/production/create',
  GET_BY_ID: '/api/production/get-by-id',
  UPDATE: '/api/production/update',
  DELETE: '/api/production/delete',
  IMPORT: '/api/production/import',
  EXPORT: '/api/production/export-excel',
};

export const PRODUCT_CATEGORY_API = {
  SEARCH: '/api/production/category/get',
  CREATE: '/api/production/category/create',
  GET_BY_ID: '/api/production/category/get',
  UPDATE: '/api/production/category/update',
  DELETE: '/api/production/category/delete',
};

export const ROLE_API = {
  GET_LIST: '/api/role/get-brief-list',
};

// Công nợ
export const DEBT_API = {
  GET_LIST: '/api/debt/get-list',
};

// phản hồi khách hàng
export const FEEDBACK_API = {
  GET_LIST: '/api/feedback/get-list',
};

// Giao dịch
export const TRANSACTION_API = {
  GET_LIST: '/api/transaction/get-list',
  CREATE: 'api/transaction/get-list',
  GET_BY_ID: 'api/transaction/get-by-id',
  UPDATE: 'api/transaction/update-by-id',
  DELETE: 'api/transaction/delete-by-id',
  EXPORT: 'api/transaction/get-list',
  APPORVE: 'api/transaction/approve-by-id',
};

// hình thức giao dịch
export const TRANSACTION_METHOD_API = {
  GET_LIST: '/api/transaction/get-form-list',
};

// hình thức giao dịch
export const TRANSACTION_TYPE_API = {
  GET_LIST: '/api/transaction/get-type-list',
};

// đơn hàng
export const ORDER_API = {
  SEARCH: '/api/order/get-list',
  GET_BY_ID: '/api/order/get-by-id',
  UPDATE: '/api/order-status/change-approve-amount',
  APPROVE: '/api/order/update',
  APPROVE_LIST: '/api/order/approve-order-amounts',
  APPROVE_CHECK_LIST: '/api/order/approve-orders',
  DECLINE: '/api/order-status/change-status-ver1',
  EXPORT: 'api/order/export-list-order',
  EXPORT_DETAIL: '/api/order/export-order-detail',
};

// đơn hàng trả
export const RETURN_ORDER_API = {
  SEARCH: '/api/return-order/get-list',
  GET_BY_ID: '/api/return-order/get-by-id',
  UPDATE: '/api/return-order/update',
  APPROVE: '/api/order-status/orders',
  EXPORT: '/api/return-order/download',
};

//điều hướng thanh toán
export const PAYMENT_NAVIGATION_API = {
  SEARCH: '/api/payment-direction/user/get-list',
  GET_DETAIL: '/api/payment-direction/user/get-list',
  UPDATE: '/api/payment-direction/user/update',
};

// thông báo
export const NOTIFICATION_API = {
  SEARCH: '/api/notification/get',
  SEEN: '/api/notification/make-seen',
};

//báo cáo
export const REPORT_API = {
  SEARCH: '/api/report/get-list',
  EXPORT: '/api/report/get-list/download',
  SEARCHV2: '/api/report/order',
  DETAILV2: '/api/report/order-detail',
  EXPORTV2: '/api/report/export-order',
  EXPORT_DETAILV2: '/api/report/export-order-detail',
};

//báo cáo
export const ORDER_PLACE_API = {
  SEARCH: '/api/report/ordering-production',
  DETAIL: '/api/report/ordering-production',
  EXPORT_V1: '/api/report/download/ordering-production',
  EXPORT_V2: '/api/report/download/ordering-production',
};

//bảo lãnh
export const GUARANTEE_API = {
  SEARCH: '/api/guarantee/get-list',
  UPDATE: '/api/guarantee/update',
};
