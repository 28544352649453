import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// type cartInfo = {
//   id: number;
//   name: string;
//   amount: number;
//   price: number;
// };

type initType = {
  transactionMethod: Array<any>;
};

const initialState:initType = {
  transactionMethod: [],
};

const TransactionMethodSlice = createSlice({
  name: 'transactionMethod',
  initialState: initialState,
  reducers: {
    searchListTransactionMethod: (state: any, action: PayloadAction<any>) => {
      
 state.transactionMethod = action.payload
    }
  }
   
});

export const { searchListTransactionMethod } = TransactionMethodSlice.actions;

export default TransactionMethodSlice.reducer;
