import React from 'react';
import {
  AccountRoutes,
  TransactionRoutes,
  OrderRoutes,
  ProductRoutes,
  DebtRoutes,
  OrderReturnRoutes,
  AppRoutes,
  FeedbackRoutes,
  AccountInternalRoutes,
  PaymentNagvigationRoutes,
  ProductCategoryRoutes,
  AreaRoutes,
  BankRoutes,
  ReportRoutes,
  OrderPlaceRoutes,
  GuaranteeRoutes,
} from 'src/helpers/app.routes';
import { SideBarRoute } from 'src/layouts/SideBar/constants';
// Danh mục tài khoản đại lý
const AccountListPage = React.lazy(async () => await import('src/pages/danh-muc-tai-khoan-dai-ly/danh-sach'));
const AccountCreatePage = React.lazy(async () => await import('src/pages/danh-muc-tai-khoan-dai-ly/tao-moi'));
const AccountDetailPage = React.lazy(async () => await import('src/pages/danh-muc-tai-khoan-dai-ly/chi-tiet'));

// Danh mục tài khoản nội bộ
const AccountInternalListPage = React.lazy(async () => await import('src/pages/danh-muc-tai-khoan-noi-bo/danh-sach'));
const AccountInternalCreatePage = React.lazy(async () => await import('src/pages/danh-muc-tai-khoan-noi-bo/tao-moi'));
const AccountInternalDetailPage = React.lazy(async () => await import('src/pages/danh-muc-tai-khoan-noi-bo/chi-tiet'));

// Quản lý sản phẩm
const ProductListPage = React.lazy(async () => await import('src/pages/quan-ly-san-pham/danh-sach'));
const ProductCreatePage = React.lazy(async () => await import('src/pages/quan-ly-san-pham/tao-moi'));
const ProductDetailPage = React.lazy(async () => await import('src/pages/quan-ly-san-pham/chi-tiet'));

// Quản lý dòng sản phẩm
const ProductCategoryListPage = React.lazy(async () => await import('src/pages/danh-muc-dong-san-pham/danh-sach'));
const ProductCategoryDetailPage = React.lazy(async () => await import('src/pages/danh-muc-dong-san-pham/chi-tiet'));

// quản lý giao dịch
const TransactionListPage = React.lazy(async () => await import('src/pages/cong-no/quan-ly-giao-dich/danh-sach'));
const TransactionCreatePage = React.lazy(async () => await import('src/pages/cong-no/quan-ly-giao-dich/tao-moi'));
const TransactionDetailPage = React.lazy(async () => await import('src/pages/cong-no/quan-ly-giao-dich/chi-tiet'));

// danh sách công nợ
const DebtListPage = React.lazy(async () => await import('src/pages/cong-no/danh-sach-cong-no/danh-sach'));

// Danh sách đơn hàng
const OrderListPage = React.lazy(async () => await import('src/pages/danh-sach-don-hang/danh-sach'));
const OrderDetailPage = React.lazy(async () => await import('src/pages/danh-sach-don-hang/chi-tiet'));

// Danh sách đơn hàng trả
const OrderReturnListPage = React.lazy(async () => await import('src/pages/danh-sach-don-hang-tra/danh-sach'));
const OrderReturnDetailPage = React.lazy(async () => await import('src/pages/danh-sach-don-hang-tra/chi-tiet'));

// Danh sách đơn hàng đặt
const OrderPlaceListPage = React.lazy(async () => await import('src/pages/danh-sach-don-hang-dat/danh-sach'));
const OrderPlaceDetailPage = React.lazy(async () => await import('src/pages/danh-sach-don-hang-dat/chi-tiet'));
// const OrderReturnDetailPage = React.lazy(async () => await import('src/pages/danh-sach-don-hang-tra/chi-tiet'));

// Phản hồi khách hàng
const FeedbackListPage = React.lazy(async () => await import('src/pages/phan-hoi-khach-hang/danh-sach'));
//điều hướng thanh toán
const PaymentNagvigationPage = React.lazy(async () => await import('src/pages/dieu-huong-thanh-toan/danh-sach'));

// Danh mục khu vực
const AreaListPage = React.lazy(async () => await import('src/pages/danh-muc-khu-vuc/danh-sach'));
//Danh mục tài khoản ngân hàng
const BankListPage = React.lazy(async () => await import('src/pages/danh-muc-tai-khoan-ngan-hang/danh-sach'));
//Báo cáo
const ReportPage = React.lazy(async () => await import('src/pages/bao-cao/danh-sach'));
const ReportPageV2 = React.lazy(async () => await import('src/pages/bao-cao-v2/danh-sach'));
const ReportDetailPageV2 = React.lazy(async () => await import('src/pages/bao-cao-v2/chi-tiet'));

// Bảo lãnh/Thế chấp
const GuaranteePage = React.lazy(async () => await import('src/pages/bao-lanh-the-chap/danh-sach'));

export const routers = [
  // Danh mục tài khoản đại lý
  {
    path: AccountRoutes.list,
    element: <AccountListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  {
    path: AccountRoutes.create,
    element: <AccountCreatePage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  {
    path: AccountRoutes.detail,
    element: <AccountDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  // Danh mục tài khoản nội bộ
  {
    path: AccountInternalRoutes.list,
    element: <AccountInternalListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ADMIN],
  },
  {
    path: AccountInternalRoutes.create,
    element: <AccountInternalCreatePage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ADMIN],
  },
  {
    path: AccountInternalRoutes.detail,
    element: <AccountInternalDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.ADMIN],
  },
  // Quản lý sản phẩm
  {
    path: ProductRoutes.list,
    element: <ProductListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  {
    path: ProductRoutes.create,
    element: <ProductCreatePage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  {
    path: ProductRoutes.detail,
    element: <ProductDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  // Quản lý  dòng sản phẩm
  {
    path: ProductCategoryRoutes.list,
    element: <ProductCategoryListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  {
    path: ProductCategoryRoutes.detail,
    element: <ProductCategoryDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  // quản lý giao dịch
  {
    path: TransactionRoutes.list,
    element: <TransactionListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  {
    path: TransactionRoutes.create,
    element: <TransactionCreatePage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  {
    path: TransactionRoutes.detail,
    element: <TransactionDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  // quản lý công nợ
  {
    path: DebtRoutes.list,
    element: <DebtListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  // Danh sách đơn hàng
  {
    path: OrderRoutes.list,
    element: <OrderListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  {
    path: OrderRoutes.detail,
    element: <OrderDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  // Danh sách đơn hàng trả
  {
    path: OrderReturnRoutes.list,
    element: <OrderReturnListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  {
    path: OrderReturnRoutes.detail,
    element: <OrderReturnDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  //Danh sách đơn hàng đặt
  {
    path: OrderPlaceRoutes.list,
    element: <OrderPlaceListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  {
    path: OrderPlaceRoutes.detail,
    element: <OrderPlaceDetailPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  // phản hồi khách hàng
  {
    path: FeedbackRoutes.list,
    element: <FeedbackListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER, SideBarRoute.ACCOUNTANT],
  },
  //điều hướng thanh toán
  // {
  //   path: PaymentNagvigationRoutes.list,
  //   element: <PaymentNagvigationPage />,
  //   permission: [SideBarRoute.SUPER_ADMIN],
  // },
  // danh mục khu vực
  {
    path: AreaRoutes.list,
    element: <AreaListPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.CONSUMER],
  },
  // tài khoản ngân hàng
  {
    path: BankRoutes.list,
    element: <BankListPage />,
    permission: [SideBarRoute.SUPER_ADMIN],
  },
  //báo cáo
  {
    path: ReportRoutes.list,
    element: <ReportPage />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.MARKET],
  },
  //báo cáo v2
  {
    path: ReportRoutes.listV2,
    element: <ReportPageV2 />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.MARKET],
  },
  {
    path: ReportRoutes.detailV2,
    element: <ReportDetailPageV2 />,
    permission: [SideBarRoute.SUPER_ADMIN, SideBarRoute.MARKET],
  },

  // Bảo lãnh/Thế chấp
  {
    path: GuaranteeRoutes.list,
    element: <GuaranteePage />,
    permission: [SideBarRoute.SUPER_ADMIN],
  },
];
